import { Injectable } from '@angular/core';
import { GoodsAndServicesClassificationService } from 'src/api/legal/services';
import { GoodsAndServicesAssemblerService } from '../assemblers/goods-and-services-assembler.service';
import { Observable } from 'rxjs';
import { GoodsAndServices } from 'src/app/view-models/goodsAndServices/goods-and-services';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GoodsAndServicesService {

  constructor(private _api: GoodsAndServicesClassificationService, private _assembler: GoodsAndServicesAssemblerService) { }

  public list(): Observable<GoodsAndServices[]> {
    return this._api.listGoodsAndServicessClassifications().pipe(
      map(response => {
        return response.map(x => this._assembler.toViewModel(x));
      })
    );
  }
}
