import { Injectable } from '@angular/core';
import { GoodsAndServicessClassificationDto } from 'src/api/legal/models';
import { GoodsAndServices } from 'src/app/view-models/goodsAndServices/goods-and-services';

@Injectable({
  providedIn: 'root'
})
export class GoodsAndServicesAssemblerService {
  
  public toViewModel(other: GoodsAndServicessClassificationDto): GoodsAndServices {
    const model = new GoodsAndServices(other.codename ?? '', other.name ?? '');
    model.id = other.id ?? null;
    model.parentId = other.parentId ?? null;

    return model;
  }

  public toDto(other: GoodsAndServices): GoodsAndServicessClassificationDto {
    return {
      id: other.id ?? undefined,
      parentId: other.parentId ?? undefined,
      codename: other.codename ?? undefined,
      name: other.name ?? undefined
    };
  }
}
