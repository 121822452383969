<h1>
    Креативы
</h1>

<div class="mt-5">
    <div class="grid">
        <div class="col-12 sm:col-10">
            <div class="flex align-items-center h-full">
                Здесь отображается список всех созданных рекламных креативов.
            </div>
        </div>

        <div class="col-12 sm:col-2">
            <button pButton type="button" label="Создать" class="w-full" (click)="CreateItemClicked()"></button>
        </div>
    </div>
</div>

<div class="mt-3">
    <app-creative-search-form (onQueryChanged)="QueryChanged($event)"></app-creative-search-form>
</div>

<div class="mt-3 fancy-card">
    <div class="fancy-card-content">
        <p-table [value]="items" [loading]="isLoading" dataKey="id" [showCurrentPageReport]="true"
                [lazy]="true" (onLazyLoad)="OnLazyLoad($event)"
                [paginator]="true" [rows]="10" [totalRecords]="itemsTotalCount" [rowsPerPageOptions]="[10,25,50]"
                currentPageReportTemplate="Показаны с {first} по {last} из {totalRecords} строчек" >
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 350px">Креатив</th>
                            <th style="width: 250px">Форма/Тип</th>
                            <th>Ссылка</th>
                            <th style="width: 230px;">Операции</th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-model>
                        <tr>
                            <td>
                                <div [ngClass]="{'flex align-items-center': true, 'flex-column': model.userVerticalLayout}">
                                    <img #image [src]="model.previewLink" [ngClass]="{'block': true, 'preview-horizontal mr-3': !model.userVerticalLayout, 'preview-vertical mb-3': model.userVerticalLayout}" (load)="onPreviewLoaded(image, model)">
                                    

                                    <div>
                                        
                                        <div class="gap-3">
                                            <p-tag class="mr-3" *ngIf="model.selfAdvertisementCounterpartyId" severity="success" value="Самореклама"></p-tag>

                                            <strong>{{model.name}}</strong>
                                        </div>
                                        <div class="text-small text-muted">
                                            {{model.description}}
                                        </div>

                                        <div class="mb-3 text-small text-muted">Создано {{model.createdAt |date}}</div>

                                        <ng-container *ngIf="model.selfAdvertisementCounterpartyId">
                                            <button *ngIf="model.marker" class="mb-3 block w-full text-center p-button-sm" pButton ngxClipboard [cbContent]="model.marker">
                                                Копировать маркер ЕРИР
                                            </button>

                                            <app-sync-button 
                                            [erirId]="model.erirId"
                                            [isAcceptedByErir]="model.isAcceptedByErir" 
                                            [isSyncing]="model.isSyncing"
                                            [isSynced]="model.isSynced"
                                            (onClicked)="SyncClick(model)"></app-sync-button>
                                        </ng-container>
                                    </div>
                                </div>
                                
                                
                            </td>

                            <td>
                                <i class="pi pi-desktop mr-2"></i> <span class="text-small" pTooltip="Форма креатива">{{model.form.name}}</span><br>
                                <i class="pi pi-credit-card mr-2"></i><span class="text-small" pTooltip="Тип кампании">{{model.campaignType.name}}</span>
                            
                                <ng-container *ngIf="model.economicClassifications && model.economicClassifications.length > 0">
                                    <div class="mt-3">
                                        <div class="text-small">ОКВЭДы:</div>
                                        <div class="flex flex-wrap">
                                            <ng-container *ngFor="let economic of model.economicClassifications">
                                                <p-tag *ngIf="economic.economicClassification" styleClass="mr-3 p-tag-outline" [value]="economic.economicClassification.value" [pTooltip]="economic.economicClassification.name"></p-tag>
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-container>
                            </td>

                            <td class="">
                                <!-- {{model.adLink}} -->

                                <div class="gap-2 flex flex-wrap align-items-center text-small" [pTooltip]="model.adLink">
                                    <a [attr.href]="model.adLink" target="_blank" rel="noreferrer" class="no-underline font-bold">
                                        {{model.urlSegments[1]}}
                                    </a>

                                    <ng-container *ngFor="let segment of model.urlSegments | slice:2; let i = index;">
                                        <i class="pi pi-chevron-right text-muted text-small"></i>
                                        
                                        <span class="text-muted">
                                            {{segment}}
                                        </span>
                                    </ng-container>
                                </div>
                            </td>

                            <td>
                                <div class="grid">
                                    <div class="col-12">
                                        <button [disabled]="model.isSyncing" pButton type="button" icon="pi pi-pencil" label="Правка" class="w-full p-button-sm p-button-text" (click)="UpdateItemClicked(model)"></button>
                                    </div>
                                    <!-- <div class="col-12">
                                        <app-sync-button [isSyncing]="model.isSyncing" [isSynced]="model.isSynced" (onClicked)="SyncClick(model)"></app-sync-button>
                                    </div> -->
                                </div>
                            </td>
                        </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<div  class="mt-3 px-3 text-small">
    Маркеры креативов можно найти на странице "договоры" (открыв режим редактирования созданного "договора") в разделе "Маркировка креативов".<br>
    После получения маркера в случае редактирования креатива его данные будут переданы автоматически.
</div>

<p-confirmDialog acceptIcon="pi pi-send" acceptLabel="Передать в ОРД" rejectLabel="Отмена" rejectButtonStyleClass="p-button-danger p-button-text" [style]="{width: '50vw'}"></p-confirmDialog>