export class GoodsAndServices {
    public id: string|null = null;
    public parentId: string|null = null;

    public codename: string;
    public name: string;

    public disabled: boolean = true;

    constructor(codename: string, name: string) {
        this.codename = codename;
        this.name = name;
    }
}