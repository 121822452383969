import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, concat, from, merge, of } from 'rxjs';
import { bufferCount, map, mergeMap } from 'rxjs/operators';
import { CreativeApiService } from 'src/app/services/api/creative-api.service';
import { EconomicClassificationApiService } from 'src/app/services/api/economic-classification-api.service';
import { CreativeCampaignType, CreativeCampaignTypes, CreativeEconomicClassificationViewModel, CreativeForm, CreativeForms, CreativeGoodsAndServicesViewModel, CreativeViewModel } from 'src/app/view-models/creative/creative-view-model';
import { EconomicClassification } from 'src/app/view-models/economic-classification/economic-classification';
import { UploadImageComponent } from '../upload-image/upload-image.component';
import { CounterpartyViewModel } from 'src/app/view-models/counterparty/counterparty-view-model';
import { CounterpartyService } from 'src/api/legal/services';
import { CounterpartyApiService } from 'src/app/services/api/counterparty-api.service';
import { GoodsAndServicesService } from 'src/app/services/api/goods-and-services.service';
import { GoodsAndServices } from 'src/app/view-models/goodsAndServices/goods-and-services';

@Component({
  selector: 'app-creative-dialogue',
  templateUrl: './creative-dialogue.component.html',
  styleUrls: ['./creative-dialogue.component.scss']
})
export class CreativeDialogueComponent implements OnInit {
  public isBusy: boolean = false;

  public form: FormGroup;

  public isCreateMode: boolean = false;
  private _values: CreativeViewModel | null;

  public get isInvalid(): boolean {
    return !this.form.valid || this.selectedEconomicClassifications.length === 0 || (!this.isTextForm && !this.form.getRawValue().previewLink) || (this.isTextForm && !this.form.getRawValue().description);
  }

  public get creativeForms(): CreativeForm[] {
    return CreativeForms;
  }

  public get creativeCampaignTypes(): CreativeCampaignType[] {
    return CreativeCampaignTypes;
  }

  public economicClassifications: EconomicClassification[] = [];

  public selectedEconomicClassifications: EconomicClassification[] = [];
  public selectedGoodsAndServices: GoodsAndServices[] = [];

  public counterparties: CounterpartyViewModel[] = [];

  public get isTextForm(): boolean {
    return this.form.getRawValue().formCode === 'text';
  }

  public goodsAndServices: GoodsAndServices[] = [];

  constructor(private creativeApi: CreativeApiService,
    private economicClassificationApi: EconomicClassificationApiService,
    private _goodsAndServicesApi: GoodsAndServicesService,
    private _counterpartyApi: CounterpartyApiService,
    private dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) {

    this._values = this.config.data ?? null;
    this.isCreateMode = (!this.config.data?.id) ?? false;

    this.form = new FormGroup({
      id: new FormControl({
        value: this._values?.id ?? null,
        disabled: false
      }),
      name: new FormControl({
        value: this._values?.name,
        disabled: false
      }, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(512)
      ]),
      selfAdvertisementCounterpartyId: new FormControl<string | null>({
        value: this._values?.selfAdvertisementCounterpartyId ?? null,
        disabled: false
      }, []),
      description: new FormControl({
        value: this._values?.description,
        disabled: false
      }, [
        Validators.maxLength(512)
      ]),
      formCode: new FormControl({
        value: this._values?.formCode,
        disabled: !this.isCreateMode
      }, [
        Validators.required
      ]),
      campaignType: new FormControl({
        value: this._values?.campaignTypeCode,
        disabled: false
      }, [
        Validators.required
      ]),
      adUrl: new FormControl({
        value: this._values?.adLink,
        disabled: false
      }, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(512)
      ]),
      previewLink: new FormControl({
        value: this._values?.previewLink,
        disabled: false
      }, [
        Validators.minLength(1),
        Validators.maxLength(512)
      ]),
    });
  }

  ngOnInit(): void {
    this.economicClassificationApi.list().subscribe(result => {
      this.economicClassifications = result;

      this.RefreshSelectedEconomics();
    });

    this._counterpartyApi.Load(1, 500).subscribe(response => {
      this.counterparties = response ? this._counterpartyApi.items : [];
    });

    this._goodsAndServicesApi.list().subscribe(result => {

      // Скрыть те, что нельзя выбирать
      result.forEach(goods => {
        goods.disabled = result.findIndex(x => x.parentId === goods.id) >= 0;
      });

      this.goodsAndServices = result.filter(x => !x.disabled);

      this.RefreshSelectedGoodsAndServices();
    });
  }

  public Submit(): void {
    if (!this.form.valid) {
      return;
    }

    const model = this.GenerateModel();

    this.isBusy = true;

    if (model.id) {
      this.UpdateEntity(model.id, model);
    } else {
      this.CreateEntity(model);
    }
  }

  public openUploadImageDialog(): void {
    const ref = this.dialogService.open(UploadImageComponent, {
      header: 'Загрузка изображения',
      width: '50%'
    });

    ref.onClose.subscribe((data: string) => {
      this.form.controls['previewLink'].setValue(`https://lk.marketing-tech.ru/uploads/${data ?? ''}`);
    });
  }

  private GenerateModel(): CreativeViewModel {
    const values = this.form.getRawValue();

    const model = new CreativeViewModel(values.name, values.formCode, values.campaignType);

    model.id = values.id ?? null;
    model.selfAdvertisementCounterpartyId = values.selfAdvertisementCounterpartyId ?? null;
    model.description = values.description ?? null;
    model.adLink = values.adUrl ?? null;
    model.previewLink = values.previewLink ?? null;
    model.isPoliticAd = values.isPoliticAd ?? false;
    model.isSocialAd = values.isPoliticAd ?? false;

    return model;
  }

  private CreateEntity(model: CreativeViewModel): void {
    this.creativeApi.Create(model).subscribe(id => {

      this.ProcessEconomicsClassification(id, model).pipe(
        mergeMap(x => {
          return this.ProcessGoodsAndServices(id, model);
        })
      ).subscribe(() => {
        this.isBusy = false;
        this.ref.close();
      });
    });
  }

  private UpdateEntity(id: string, model: CreativeViewModel): void {
    this.creativeApi.Update(id, model).subscribe(x => {

      this.ProcessEconomicsClassification(id, this._values ?? model).pipe(
        mergeMap(x => {
          return this.ProcessGoodsAndServices(id, model);
        })
      ).subscribe(() => {
        this.isBusy = false;
        this.ref.close();
      });

    });
  }

  private ProcessEconomicsClassification(id: string, model: CreativeViewModel): Observable<boolean> {
    //this._values
    const itemsToRemove = model.economicClassifications.filter(x => x.economicClassificationId &&
      this.selectedEconomicClassifications.findIndex(y => y.id === x.economicClassificationId) < 0
    );

    const itemsToCreate = this.selectedEconomicClassifications
      .filter(x => model.economicClassifications.findIndex(y => y.economicClassificationId === x.id) < 0)
      .map(x => {
        return new CreativeEconomicClassificationViewModel(x.id ?? '')
      });

    const requests: Observable<boolean>[] = [];

    if (itemsToRemove.length > 0) {
      requests.push(
        this.creativeApi.RemoveEconomicsClassifications(id, itemsToRemove.map(x => x?.economicClassificationId ?? ''))
      );
    }

    if (itemsToCreate.length > 0) {
      requests.push(
        this.creativeApi.AddEconomicsClassifications(id, itemsToCreate)
      );
    }

    if (requests.length === 0) {
      return of(true);
    }

    return concat(...requests);
  }

  private ProcessGoodsAndServices(id: string, model: CreativeViewModel): Observable<boolean> {
    //this._values
    const itemsToRemove = this._values?.goodsAndServices.filter(x => 
      x.goodsAndServicesId &&
      this.selectedGoodsAndServices.findIndex(y => y.id === x.goodsAndServicesId) < 0
    ) ?? [];

    const itemsToCreate = this.selectedGoodsAndServices
      .filter(x => model.goodsAndServices.findIndex(y => y.goodsAndServicesId === x.id) < 0)
      .map(x => {
        return new CreativeGoodsAndServicesViewModel(x.id ?? '')
      });

    const requests: Observable<boolean>[] = [];

    if (itemsToRemove.length > 0) {
      requests.push(
        this.creativeApi.RemoveGoodsAndServices(id, itemsToRemove.map(x => x?.goodsAndServicesId ?? ''))
      );
    }

    if (itemsToCreate.length > 0) {
      requests.push(
        this.creativeApi.AddGoodsAndServices(id, itemsToCreate)
      );
    }

    if (requests.length === 0) {
      return of(true);
    }

    return concat(...requests);
  }

  private RefreshSelectedEconomics(): void {
    if (this._values?.economicClassifications.length === 0 || this.economicClassifications.length === 0) {
      return;
    }

    this._values?.economicClassifications.forEach(eco => {
      if (this.selectedEconomicClassifications.findIndex(x => x.id === eco.economicClassificationId) < 0) {
        const stored = this.economicClassifications.find(x => x.id === eco.economicClassificationId);

        if (stored) {
          this.selectedEconomicClassifications.push(stored);
        }
      }
    });
  }

  private RefreshSelectedGoodsAndServices(): void {
    if (this._values?.goodsAndServices.length === 0 || this.goodsAndServices.length === 0) {
      return;
    }

    this._values?.goodsAndServices.forEach(goods => {
      if (this.selectedGoodsAndServices.findIndex(x => x.id === goods.goodsAndServicesId) < 0) {
        const stored = this.goodsAndServices.find(x => x.id === goods.goodsAndServicesId);

        if (stored) {
          this.selectedGoodsAndServices.push(stored);
        }
      }
    });
  }
}
